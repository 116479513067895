import Breadcrumbs from "../lib/Breadcrumbs";
import { Form, WithLabel, InputField, HuePicker } from "@atrocit/scl";
import React, { useEffect, useState } from "react";
import ButtonArea from "../lib/forms/ButtonArea";
import ValidatedButton from "../lib/forms/ValidatedButton";
import { useNavigate } from "react-router";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import SelectField from '../lib/selectfields/SelectField';
import TopBar from '../TopBar';
import PageTop from '../lib/ui/PageTop';
import ColorDot from '../lib/ui/ColorDot';

export default function AddEditRole() {
	const navigate = useNavigate();
	const { id } = useParams();

	const [ shortCode, setShortCode ] = useState('');
	const [ name, setName ] = useState('');
	const [ colorCode, setColorCode ] = useState(null);
	const [ editingMode, setEditingMode ] = useState(false);

	const { data, loading, refetch } = useQuery(gql`query Query($id: Int!){
        role(id: $id){
            id,
            shortCode,
	        colorCode,
	        name
        }
    }`, { variables: { id } });

	const [ addRole ] = useMutation(gql`mutation Mutation($shortCode: String!, $name: String!, $colorCode: String) {
        addRole(shortCode: $shortCode, name: $name, colorCode: $colorCode) { id }
    } `);

	const [ editRole ] = useMutation(gql `mutation Mutation($id: Int!, $shortCode: String!, $name: String!, $colorCode: String) {
        editRole(roleId: $id, shortCode: $shortCode, name: $name, colorCode: $colorCode) { id }
    }`);

	useEffect(() => {
		if (data == null) return;
		setShortCode(data?.role?.shortCode);
		setName(data?.role?.name);
		setColorCode(data?.role?.colorCode);
		setEditingMode(true);
	}, [ data ]);

	return <>
		<TopBar title={editingMode ? 'Rol bewerken' : 'Rol toevoegen'} />
		<div className='page'>
			<div className="app-ui">
				<PageTop
					breadcrumbSegments={[
						{ link: '/roles', label: 'Rollen' },
						{ link: '', label: editingMode ? data?.role?.name : 'Nieuwe rol' },
					]} />
				<Form
					style={{ width: 'var(--u-768)', justifyContent: 'center', alignItems: 'center', marginTop: 'var(--u-24)' }}
					onSubmit={() => {
						if (editingMode) {
							editRole({ variables: { id, shortCode, name, colorCode } }).then(() => navigate('/roles'));
						} else {
							addRole({ variables: { shortCode, name, colorCode } }).then(() => navigate('/roles'));
						}
					}}>
					<WithLabel label={'Code'}>
						<InputField onChange={setShortCode} value={shortCode} />
					</WithLabel>
					<WithLabel label={'Rolnaam'}>
						<InputField onChange={setName} value={name} />
					</WithLabel>
					<WithLabel label={'Kleur'}>
						<SelectField
							options={[
								{ value: '#811', label: 'Bordeaux' },
								{ value: '#f22', label: 'Rood' },
								{ value: '#fa0', label: 'Oranje' },
								{ value: '#ff0', label: 'Geel' },
								{ value: '#4c0', label: 'Groen' },
								{ value: '#150', label: 'Donkergroen' },
								{ value: '#0cc', label: 'Turquoise' },
								{ value: '#07f', label: 'Blauw' },
								{ value: '#028', label: 'Marineblauw' },
								{ value: '#a3f', label: 'Paars' },
								{ value: '#f3a', label: 'Roze' },
								{ value: '#a50', label: 'Bruin' },
								{ value: '#ccc', label: 'Grijs' },
								{ value: '#333', label: 'Zwart' },
							]}
							formatOptionLabel={option => <span>&nbsp;<ColorDot hex={option.value} /> &nbsp;{option.label}</span>}
							onChange={col => setColorCode(col?.value)}
							value={colorCode} />
					</WithLabel>
					<ButtonArea>
						<ValidatedButton
							type="submit"
							validations={[
								{ valid: name.length > 0, error: "De rolnaam mag niet leeg zijn" },
								{ valid: shortCode.length > 0, error: "De code mag niet leeg zijn" },
								{ valid: shortCode.length <= 4, error: "De code mag niet langer dan 4 tekens zijn" },
								{ valid: colorCode?.length > 0, error: "Er moet een kleur geselecteerd zijn" },
							]}>
							<span className="fa fa-check" />&nbsp; Rol opslaan
						</ValidatedButton>
					</ButtonArea>
				</Form>
			</div>
		</div>
	</>;
}