import { useCallback, useState } from 'react';

function safeJsonParse(value, defaultValue) {
	try {
		return JSON.parse(value);
	} catch (e) {
		return defaultValue;
	}
}

function safeJsonStringify(value) {
	try {
		return JSON.stringify(value);
	} catch (e) {
		return null;
	}
}

function readValueFromSessionStorage(storageKey, defaultValue) {
	const value = sessionStorage.getItem(storageKey);
	return value ? safeJsonParse(value, defaultValue) : defaultValue;
}

export default function useSessionState(defaultValue, key) {
	const storageKey = 'pp.sessionHook.' + key;
	const [ value, setter ] = useState(readValueFromSessionStorage(storageKey, defaultValue));

	const customSetter = useCallback(newValue => {
		const currentStoredValue = sessionStorage.getItem(storageKey) ? safeJsonParse(sessionStorage.getItem(storageKey), defaultValue) : defaultValue;
		const actualNewValue = (typeof newValue === 'function') ? newValue(currentStoredValue) : newValue;
		const stringValue = safeJsonStringify(actualNewValue);

		if (stringValue != null) {
			sessionStorage.setItem(storageKey, stringValue);
		}

		setter(actualNewValue);
	}, [ storageKey, defaultValue, setter ]);

	return [ value, customSetter ];
}