import TopBar from '../TopBar';
import { useMemo, useState } from 'react';
import UserSelectField from '../lib/selectfields/UserSelectField';
import { Button, ButtonGroup, LoadingOverlay, usePersistentState, WithLabel } from '@atrocit/scl';
import { gql, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { FormattedDate } from 'react-intl';
import { AbsenceType } from './AbsenceType';
import AbsenceYearCalendarPerUser from './AbsenceYearCalendarPerUser';
import BlurredZeroValue from '../lib/tables/BlurredZeroValue';

export default function AbsencesByUser() {
	const [ user, setUser ] = useState(null);
	const [ viewMode, setViewMode ] = usePersistentState('TABLES', 'absencesByUserViewMode');
	const [ year, setYear ] = useState(DateTime.now().startOf('year'));

	const specialDaysQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!) {
		specialDays(start: $start, end: $end) {
			id,
			date,
			name,
			colorCode
        }
	}`, { variables: { start: DateTime.now().startOf('day').minus({ years: 100 }).toUTC().toISO(), end: DateTime.now().startOf('day').plus({ years: 100 }).toUTC().toISO() } });
	const specialDays = specialDaysQuery?.data?.specialDays ?? [];

	const absenceQuery = useQuery(gql`query Query($userId: Int!, $start: Instant!, $end: Instant!) {
		absencesInRangeForUser(userId: $userId, start: $start, end: $end) {
			id,
			absenceType,
			absenceStatus,
			start,
			end,
			remark,
			user {
				id,
				fullName,
			},
			workerRequested,
		}
	}`, { skip: user == null, variables: { userId: user,
		start: viewMode == 'TABLES' ? DateTime.now().startOf('day').minus({ years: 100 }).toUTC().toISO() : year.startOf('year').toUTC().toISO(),
		end: viewMode == 'TABLES' ? DateTime.now().startOf('day').plus({ years: 100 }).toUTC().toISO() : year.endOf('year').toUTC().toISO(),
	} });
	const absences = absenceQuery?.data?.absencesInRangeForUser ?? [];

	const absencesByType = useMemo(() => {
		return absences.reduce((tot, elem) => ({ ...tot, [elem.absenceType]: [ ...(tot[elem.absenceType] ?? []), elem ] }), {});
	}, [ absences ]);

	return <>
		{absenceQuery?.loading && <LoadingOverlay />}
		<TopBar title="Absenties per persoon" />

		<div className="grey-page-bg">
			<div className="page">
				<div style={{ display: 'flex', gap: 'var(--u-16)' }}>
					<div style={{ flex: 1 }}>
						<WithLabel label="Gebruiker">
							<UserSelectField onChange={setUser} value={user} />
						</WithLabel>
					</div>
					<div>
						<WithLabel label="&nbsp;">
							<ButtonGroup>
								<Button onClick={() => setViewMode('TABLES')} active={viewMode == 'TABLES'} level={viewMode == 'TABLES' ? 'primary' : 'secondary'}><span className="fa fa-th-list" /></Button>
								<Button onClick={() => setViewMode('CALENDAR')} active={viewMode == 'CALENDAR'} level={viewMode == 'CALENDAR' ? 'primary' : 'secondary'}><span className="fa fa-calendar-o" /></Button>
							</ButtonGroup>
						</WithLabel>
					</div>
				</div>

				{viewMode == 'TABLES' && <>
					<h2>Toekomstige absenties</h2>
					<AbsenceTable absences={absences.filter(a => DateTime.fromISO(a.end) >= DateTime.now()).sort((a, b) => (a.start < b.start ? -1 : 1))} />

					<br />
					<h2>Absenties in het verleden</h2>
					<p style={{ margin: 0, marginBottom: 'var(--u-8)' }}>Meest recente absenties staan bovenaan</p>
					<AbsenceTable absences={absences.filter(a => DateTime.fromISO(a.end) < DateTime.now()).sort((a, b) => (a.start > b.start ? -1 : 1))} />
				</>}

				{viewMode == 'CALENDAR' && <>
					<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--u-16)' }}>
						<div style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'center' }}>
							<Button onClick={() => setYear(y => y.minus({ years: 1 }).startOf('year'))}><span className="fa fa-chevron-left" /></Button>
							<div style={{ fontWeight: 'bold' }}>{year.toFormat('yyyy')}</div>
							<Button onClick={() => setYear(y => y.plus({ years: 1 }).startOf('year'))}><span className="fa fa-chevron-right" /></Button>
						</div>
					</div>
					<AbsenceYearCalendarPerUser specialDays={specialDays} absences={absences} start={year} />
					<br />
					<table className="table">
						<colgroup>
							<col width="*" />
							<col width="120" />
							<col width="120" />
							<col width="120" />
							<col width="120" />
						</colgroup>

						<thead>
							<tr>
								<th>Type</th>
								<th className="tbl-align-right">Aantal verzocht</th>
								<th className="tbl-align-right">Dg verzocht</th>
								<th className="tbl-align-right">Aantal totaal</th>
								<th className="tbl-align-right">Dagen totaal</th>
							</tr>
						</thead>

						<tbody>
							{Object.keys(absencesByType).sort((a, b) => {
								const totalDaysA = absencesByType[a].map(a => DateTime.fromISO(a.end).diff(DateTime.fromISO(a.start), 'days').days).reduce((a, b) => a + b, 0);
								const totalDaysB = absencesByType[b].map(a => DateTime.fromISO(a.end).diff(DateTime.fromISO(a.start), 'days').days).reduce((a, b) => a + b, 0);
								return totalDaysB - totalDaysA;
							}).map(absenceType => {
								const absences = absencesByType[absenceType];
								const requestedAbsences = absences.filter(a => a.absenceStatus == 'REQUESTED');
								const totalDays = absences.map(a => DateTime.fromISO(a.end).diff(DateTime.fromISO(a.start), 'days').days).reduce((a, b) => a + b, 0);
								const totalRequestedDays = requestedAbsences.map(a => DateTime.fromISO(a.end).diff(DateTime.fromISO(a.start), 'days').days).reduce((a, b) => a + b, 0);

								return <tr key={absenceType}>
									<td><AbsenceType type={absenceType} /></td>
									<td className="tbl-align-right"><BlurredZeroValue value={requestedAbsences.length} suffix={<>&times;</>} /></td>
									<td className="tbl-align-right"><BlurredZeroValue value={Math.round(totalRequestedDays)} suffix=" dg" /></td>
									<td className="tbl-align-right"><BlurredZeroValue value={absences.length} suffix={<>&times;</>} /></td>
									<td className="tbl-align-right"><BlurredZeroValue value={Math.round(totalDays)} suffix=" dg" /></td>
								</tr>;
							})}
						</tbody>
					</table>
				</>}
			</div>
		</div>
	</>;
}

function AbsenceTable({ absences }) {
	return <table className="table table-fw">
		<colgroup>
			<col width="30" />
			<col width="20" />
			<col width="30" />
			<col width="120" />
			<col width="30" />
			<col width="120" />
			<col width="60" />
			<col width="100" />
			<col width="*" />
		</colgroup>
		<thead>
			<tr>
				<th />
				<th title="Aangevraagd door arbeider"><span className="fa fa-user" /></th>
				<th colSpan={2}>Van</th>
				<th colSpan={2}>Tot</th>
				<th className="tbl-align-right">Dagen</th>
				<th>Type</th>
				<th>Opmerking</th>
			</tr>
		</thead>
		<tbody>
			{absences.map(a => <tr key={a.id}>
				<td className="tbl-center">
					{a.absenceStatus == 'REQUESTED' && <span className="fa fa-question-circle" style={{ color: 'var(--col-grey-500)' }} title="Aangevraagd" />}
					{a.absenceStatus == 'APPROVED' && <span className="fa fa-check-circle" style={{ color: 'var(--col-primary-500)' }} title="Goedgekeurd" />}
					{a.absenceStatus == 'DENIED' && <span className="fa fa-times-circle" style={{ color: 'var(--col-red-500)' }} title="Afgewezen" />}
				</td>
				<td>{a.workerRequested && <span className="fa fa-user" title="Aangevraagd door arbeider" />}</td>
				<td><FormattedDate weekday="short" value={a.start} /></td>
				<td><FormattedDate month="2-digit" day="2-digit" year="numeric" value={a.start} /></td>
				<td><FormattedDate weekday="short" value={a.end} /></td>
				<td><FormattedDate month="2-digit" day="2-digit" year="numeric" value={a.end} /></td>
				<td className="tbl-align-right">
					{Math.round(DateTime.fromISO(a.end).diff(DateTime.fromISO(a.start), 'days').days)}
				</td>
				<td><AbsenceType type={a.absenceType} /></td>
				<td>{a.remark || '-'}</td>
			</tr>)}
			{absences.length == 0 && <tr>
				<td colSpan={9}>Geen resultaten</td>
			</tr>}
		</tbody>
	</table>;
}